/* General styling */
.container {
    display: flex;
    flex-wrap: wrap;
    /* Allow boxes to wrap to the next line on smaller screens */
    justify-content: space-around;
}

.circle-icon {
    width: 100px;
        height: 100px;
        border-radius: 80%;
        /* overflow: hidden; */
        background-size: cover;
        background-position: center;
        background-color: #ccc;
        margin-bottom: 10px;
       
}

.box {
    flex: 1;
    max-width: 400px;
    /* Set a maximum width for the boxes */
    padding: 20px;
    margin: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 5px -2px rgb(186, 7, 7);
}


@media only screen and (max-width: 768px) {
    .box {
        flex-basis: 50%;
    }
}
@media only screen and (max-width: 992px) {
    .box {
        flex-basis: 100%;
    }
}

#b1, #b2, #b3, #b4{
    background: rgb(149, 100, 102);
    color: #d2e2e2;
}

/* #b2 {
    background: linear-gradient(180deg, #dd729f, #bad5d522 60%);
}

#b3 {
    background: linear-gradient(180deg, #7faf1e, #bad5d522 60%);
}

#b4 {
    background: linear-gradient(180deg, #4aa4a2, #bad5d522 60%);
} */

/* Styles for the highlight paragraphs */
p2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(188, 219, 190, 0.7);
    font-family: "Space Grotesk", sans-serif;
    font-weight: bolder;
    font-size: xx-large;
    color: rgb(6, 66, 84);
    padding: 30px;
    margin-top: 40px;
}

p1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-family: "Space Grotesk", sans-serif;
    font-size: larger;
    background: #060606;
    color: wheat;
    border-radius: 10px;
    margin-bottom: 20px;
}