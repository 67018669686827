.home-boat {
    background-image: url('../Assets/boat3.jpeg');
    background-size: 1450px;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 5px solid #ece7e7;
    border-bottom: 5px solid #f7f0f0;
   margin-bottom: 80px;
}
.home-boat p5{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(188, 219, 190, 0.7);
    font-family: "Space Grotesk", sans-serif;
    font-weight: 1200;
    font-style: normal;
    font-size: xx-large;
    color: white;
    padding: 15px;
    margin-top: 140px;
    
}
.about p{
    font-family: "Roboto Mono", monospace;
    font-weight: bolder;
    font-size: xx-large;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 20px;

}
.about p1{
    display: flex;
    padding: 40px;
    font-family: "Space Grotesk", sans-serif;
    background: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 50px;
    color: #060606;
}
.about p2 {
display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(188, 219, 190, 0.7);
    font-family: "Space Grotesk", sans-serif;
    font-weight: bolder;
    font-size: xx-large;
    color: rgb(6, 66, 84);
    padding: 30px;
    margin-top: 40px;
}

.about p1, p2{
    font-family: "Quicksand", sans-serif;
        
}

.about p6{
display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-family: "DM Serif Display", serif;
    font-weight: 800;
    font-style: normal;
    font-size: xx-large;
    color: black;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 90px;
    
}

.about p3{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-family: "Space Grotesk", sans-serif;
    background: #060606;
    color: wheat;
    border-radius: 10px;
    margin-bottom: 20px;
}
.about p4 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-family: "Space Grotesk", sans-serif;
    background: #c3b8b8;
    color: rgb(108, 73, 6);
    border-radius: 10px;
    margin-bottom: 20px;
}
.about img{
    width: 100%;
    max-width: 80px;
    height: auto;
    margin-bottom: -10px;
}
