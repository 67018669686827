.navbar {
    display: flex;
        justify-content: space-around;
        padding: 30px;
        background-color: #d4eeef;
        z-index: 100;
        position: relative;
   
}

@media only screen and (max-width: 1200px) {
    .nav-menu li{
        font-size: x-large;
    }
    
}  
@media only screen and (min-width: 1200px) {
    .nav-menu li {
        font-size: x-large;
    }

}
@media only screen and (max-width: 992px) {
    .nav-menu li {
        font-size: x-large;
    }

}

@media only screen and (max-width: 578px) {
    .nav-menu li {
        font-size: small;
    }

}

@media only screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
        padding: 5px;
    }

    .nav-menu {
        align-items: end;
        gap: 0px;
        margin-left: 100px;
    }
         .nav-menu li {
             font-size: large;
         }
}

@media only screen and (max-width: 480px) {
    .navbar {
        flex-direction: column;
        padding: 10px;
    }

    .nav-logo img {
        width: 30%;
    }
    .nav-menu li{
        font-size: small;
    }
}

@media (max-width: 480px) {
  .mobileVisible {
    display: block;
  }
}

@media (min-width: 481px) {
  .mobileVisible {
    display: none;
  }
}
@media (max-width: 480px) {
    .mobileHidden {
        display: none;
    }
}
.mobileHidden {
    display: block;
}


.nav-logo img {
width: 50%;
    max-width: 60px;
    height: auto;
   
}
.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 50px;
    color: black;
    font-size: 20px;
    font-weight: 500;
    
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    gap: 3px;
    cursor: pointer;
    color: #f9f5f5;
    
}

.nav-menu hr {
    border: none;
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background-color: #f9f5f5;
}
:where(.css-dev-only-do-not-override-1qhpsh8) a{
    color: rgb(15, 15, 63);
}

.ant-drawer-body{
  font-size: x-large;
  background: rgb(216, 241, 233);
font-family: "Space Grotesk", sans-serif;
}


