.services-image{
    background-image: url('../Assets/services.png');
        background-repeat: no-repeat;
        /* background-size: 1450px; */
        background-position: center bottom;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border-top: 5px solid #ece7e7;
        border-bottom: 5px solid #f7f0f0; */
        margin-bottom: 50px;
}
@media (max-width: 480px) {
    .services-image {
        background-size: 100%;
        height: 25vh;
    }
}

@media (min-width: 481px) and (max-width: 576px) {
    .services-image {
        background-size: 100%;
        height: 35vh;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .services-image {
        background-size: 100%;
        height: 30vh;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .services-image {
        background-size: 110%;
        height: 50vh;
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .services-image {
        background-size: 100%;
        height: 55vh;
    }
}

@media (min-width: 1201px) and (max-width: 1600px) {
    .services-image {
        background-size: 100%;
        height: 75vh;
    }
}

@media (min-width: 1601px) {
    .services-image {
        background-size: 140%;
        height: 72vh;
    }
}
p3 {
    display: flex;
    justify-content: center;
        align-items: center;
    background: rgba(188, 219, 190, 0.7);
    font-family: "Space Grotesk", sans-serif;
    font-weight:bolder;
    font-style: normal;
    font-size:xx-large;
    color: rgb(7, 80, 77);
    padding: 35px;
    margin-top: 40px;
    margin-bottom: 10px;
}
p1 {
display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-family: "Space Grotesk", sans-serif;
    font-size: larger;
    background: #060606;
    color: wheat;
    border-radius: 10px;
    margin-bottom: 20px;
}
p2{
    display: flex;
    padding: 40px;
    font-family: "Space Grotesk", sans-serif;
    background: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 50px;
    color: #060606;
}

img {
    width: 100%;
    max-width: 80px;
    height: auto;
   
}