.contact{
    width:100%;
    height: 80vh;
    background-color: rgb(1, 50, 34);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

form{
    display: flex;
    background-color: antiquewhite;
    flex-direction: column;
    padding: 2vw 4vw;
    width: 80%;
    height: 70%;
    max-width: 600px;
    border-radius: 10px;
}

form h3{
    color: #555;
    font-weight: 800;
    margin-bottom: 20px;
}

form input, form textarea{
    border: 0;
    margin: 10px 0;
    padding: 20px;
    outline: none;
    background: #f5f5f5;
    font-family: 15px;
}

form button{
    padding: 15px;
    background: #ff5361;
    color: white;
    font-size: 18px;
    border: 0;
    outline: none;
    cursor: pointer;
    width: 150px;
    margin: 20px auto 0;
    border-radius: 30px;
}
.contact form button {
    transition: background-color 0.3s ease;
}

.contact form button:hover {
    background-color: #f37781;
    /* Change the color to your desired hover effect */
}
