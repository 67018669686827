.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 2px;
    position: relative;
    width: 100%;
}

.white-text {
    color: white;
    text-decoration: none;
}

.footer-logo {
    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
}

.footer-logo p {
color: #f5f3f3;
    font-size: 24px;
    font-weight: 700;
    
}
@media screen and (max-width: 768px) {
    .footer-logo {
        text-align: center;
        
    }

    .footer-logo p {
        margin-right: 0;
    }
}
@media screen and (min-width: 992px) {
    .footer-links,
    .footer-social-icons {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center; /* Center the items horizontally */
    }

    .footer-links li {
        margin: 0 10px; /* Add some spacing between the items */
    }
}

.footer-links {
    display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
}

.footer-links li {
    cursor: pointer;
}

.footer-social-icons {
    display: flex;
    gap: 20px;
}

.footer-icons-container {
    padding: 10px;
        padding-bottom: 6px;
        background: #fbfbfb;
        border: 1px solid #ebebeb;
}

.footer-icons-container img {
    width: 30px;
        height: 30px;
}

.footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #f5eded;
    font-size: 16px;
    margin-bottom: 20px;
}

.footer-copyright hr {
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;
}