/* #app {
    height: 100%
}

html,
body {
    position: relative;
    height: 100%;
} */

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 400px;
}

.swiper-slide img {
    display: block;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
}
